html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    /* Prevents scrollbars if not necessary */
    font-family: 'Montserrat', sans-serif;
}

.redirect-message {
    font-family: 'Montserrat', sans-serif;
}

.redirect-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    margin-top: 50vh;
  }